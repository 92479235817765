import { deleteTeamMember, getUser, onboardCreator, setUserAvatar, setUserProfile, updateTeamMember, useUpdateUser } from '@/api/user.api'
import store from '@/stores'
import axios from 'axios'
import { ref } from 'vue'

const user = ref(null as any)
const userStats = ref(null as any)

export default function useUser() {
    const useGetUser = async () => {
        const userResp = await getUser()
        user.value = userResp
        return userResp
    }
    
    const updateUser = async (changes: any) => {
        const userResp = await useUpdateUser(changes)
        user.value = userResp
        return userResp
    }

    const useSetUserAvatar = async (avatar: string) => {
        const userResp = await setUserAvatar(avatar)
        user.value = userResp
        return userResp
    }

    const updateUserProfile = async (updatedFields: any) => {
        const userResp = await setUserProfile(updatedFields)
        useGetUser()
        return userResp
    }

    const fetchIpAddress = async (url: string) => {
        try {
            const response = await axios.get(url)
            return response.data.ip || response.data.ipString
        }
        catch (error: any) {
            console.error(`Error fetching IP address from ${url}:`, error?.message)
            return null
        }
    }

    const getUserIPAddress = async () => {
        const apiEndpoints = ['https://api.ipify.org?format=json', 'https://api.bigdatacloud.net/data/client-ip']

        for (const url of apiEndpoints) {
            const ipAddress = await fetchIpAddress(url)
            if (ipAddress) {
                store.state.ip = ipAddress
                return ipAddress
            }
        }

        return null
    }

    const useDeleteTeamMember = async (user_id: string) => {
        const userResp = await deleteTeamMember(user_id)
        return userResp
    }

    const useUpdateTeamMember = async (member: any) => {
        const userResp = await updateTeamMember(member)
        return userResp
    }

    const useOnboardCreator = async (body: any) => {
        const userResp = await onboardCreator(body)
        return userResp
    }

    return {
        user,
        userStats,
        useUpdateTeamMember,
        useDeleteTeamMember,
        useGetUser,
        getUserIPAddress,
        useSetUserAvatar,
        updateUserProfile,
        useOnboardCreator,
        updateUser
    }
}
